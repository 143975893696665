//
//  Fragments
//  - component containing speakers (speaker-fragments)
//  - active styling for currently speaking/playing fragment
//

.fragments {
    position: relative;
    width: 100%;
    height: 100%;

    // Blockify header
    .block-header { border-width: 0px 0px 1px 0px; }

    .overflow {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 39px;
        bottom: 40px;
        overflow: hidden;
        overflow-y: auto;

    }

    li {
        border-top: 1px solid $archive-border-color;

        &:first-child { border-top: 0px; }
    }

    .fragment {
        transition: all 0.15s linear;
        padding: 5px;
        position: relative;

        &:hover, &.active { background-color: $white; }
        .thumb, .button { display: none; }
        .pointer { align-self: center; }

        .button {
            position: absolute;
            right: 5px;
            bottom: 5px;
        }

        &.active {
            .thumb, .button { display: inline-block; }
            .pointer { display: none; }
            .button { pointer-events: visible; }
        }
    }

    .speaker {
        color: inherit;
        text-decoration: none;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 5px;

        * { pointer-events: none; }

        .thumb, .start, .icn { flex: 0 0 auto; }
        .icn { color: $lightGrey; }

        .speaker-info {
            flex: 1 1 auto;
            line-height: 1.2;
        }

        .name {
            font-weight: bold;
            display: block;
        }

        .group, .start { font-size: var(--font-size-small); }

        .thumb {
            flex-basis: 60px;
            color: $white;
            position: relative;
            margin-right: 10px;

            .icn {
                position: absolute;
                left: 0px;
                right: 0px;
                top: 50%;
                font-size: 1.4rem;
                transform: translateY(-50%);
                color: inherit;
            }
        }

        @media ( min-width: $landscape ) {
            padding: 10px;
        }
    }

    .active .speaker {
        align-items: flex-start;

        .speaker-info, .start { margin-top: 5px; }
    }

    .no-content {
        text-align: center;
        margin-top: 50px;
        color: $mediumGrey;
        font-style: italic;

        .icn {
            font-size: 24px;
            display: block;
            color: $softGrey;
            margin-bottom: 10px;
        }
    }
}
