//
//  Film fragments
//  - styling for overview of film-fragments below a stream
//
.film-fragments {
    padding: 15px;
    position: relative;

    .name { flex: 1 1 auto; }
    .type, .duration .icn { color: var(--light-grey); }

    .film-fragment {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .duration {
        flex: 1 1 80px;
        border-right: 1px solid $archive-border-color;
        margin-right: 10px;
    }

    .active {
        .type { color: var(--green); }
        .name { font-style: italic; }
    }

    .dummy-button {
        visibility: hidden;
    }
}
