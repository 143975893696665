//
//  Dummy player with icon
//
.placeholder-player {
    position: relative;
    box-sizing: border-box;

    &:before {
        content: " ";
        display: block;
        padding-top: 56.25%;
    }

    .play-icn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center center;
        transform: translate( -50%, -50% );
        color: $white;
        background-color: $primary;
        box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.25 );
        font-size: 16px;
        border-radius: 100%;
        text-align: center;


        @include equalAll(40px);

        @media ( min-width: $portrait ) {
            font-size: 32px;
            text-indent: 3px;
            @include equalAll( 80px );
        }
    }

    &.is-playing, &.is-ready {
        .play-icn { opacity: 0; }
    }
}


.mediaplayer + .placeholder-player {
    display: none;
}
