//
//  Extra styling for the safari audio player
//

.is-safari .audio-overlay {
    opacity: 1;
}

.audio-stream .is-safari {
    .mediaplayer {
        position: relative;
        z-index: 1;

        &:before {
            content: " ";
            display: block;
            padding-top: 30%;
        }

        video {
            position: absolute;
            height: 100%;
            top: 0px;
            left: 0px;
            width: 100%;
        }
    }
}
