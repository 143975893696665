//
//  Status
//  - styling of status message
//

.stream-status-holder {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;
}

.stream-status {
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translateX( -50% );
    color: $white;
    background-color: $primary;
    padding: 5px 10px;
    border-radius: 0px 0px 3px 3px;
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.5 );
    transition: all 0.2s linear;
    line-height: 20px;
    box-sizing: border-box;
    transform-origin: center center;

    .status-title { font-weight: bold; }
    .description { font-size: var(--font-size-small); }

    &.in { animation: streamStatus 3s linear 0s 1 forwards; }
    &.out { transform: translate(-50%, -100% ); }

    // Color classes
    @each $name, $color in $colors {
        &.#{$name} {
            background-color: $color;
        }
    }
}

// Status IN animation
@keyframes streamStatus {
    0% {
        transform: translate( -50%, -50% ) scale(0.8);
        opacity: 0;
        top: 50%;
    }

    5% {
        transform: translate( -50%, -50% ) scale(1.2);
        top: 50%;
        opacity: 1;
        padding: 10px 15px;
        border-radius: 5px;
        margin-top: -30px;
   }

   95% {
        transform: translate( -50%, -50% ) scale(1.2);
        top: 50%;
        opacity: 1;
        padding: 10px 15px;
        border-radius: 5px;
        margin-top: -30px;
   }
}
