//
//  Base widget styling
//
.stream-widget {
    transition: all 0.15s linear;
    margin-bottom: 50px;
}

.stream-wrapper {
    outline: 1px solid $softGrey;
    background-color: $f5;
}

.stream-widget-heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    &:before {
        content: "\f03d";
        font-family: $font-awesome;
        font-weight: bold;
        color: var(--primary);
        margin-right: 5px;
    }

    .tag {
        margin-left: 5px;
        display: none;
    }
}

.audio-stream .stream-widget-heading:before { content: "\f028"; }
.is-paused .stream-widget-heading .tag { display: inline-block; }

.stream-holder {
    position: relative;
    background: #111; // Almost black

    // Exta, set width for safari
    .safari-player { width: 100%; }
}

.stream-side { border-left: 1px solid $archive-border-color; }

// Audio streaming
.audio-stream {
    .stream-holder {
        background-color: $secondary;
        background-image: linear-gradient(-45deg, rgba( $black, 0.2), rgba( $white, 0.2));
    }
}

// Loading error message
.load-error {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: $white;
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
    padding: 7px 15px;
    transform-origin: center center;
    transform: translate( -50%, -50% );
    animation: loadError 0.3s linear 1;
    text-align: center;
}

@keyframes loadError {
    0% { transform: translate( -50%, -50% ) scale( 0.6, 0.6 ) ; }
    80% { transform: translate( -50%, -50% ) scale( 1.1, 1.1 ) ; }
}
