//
//  Autoplay
//  - shows an overlay at start or end of video with previous/next buttons and option to select autoplay
//
.auto-play-overlay {
    --_btn-size: 140px;
    --_grid-gap: 40px 20px;
    --_offset: 50px;
    --_count-size: 48pt;
    position: absolute;
    inset: 0px;
    background-color: #000c;
    z-index: 10;
    visibility: hidden;
    pointer-events: none;

    @media (max-width: ( $landscape - 1px )) {
        --_btn-size: 40px;
        --_grid-gap: 10px;
        --_offset: 15px;
        --_spinner-transform: 0.5;
        --_count-size: 14pt;
    }
}

.show-auto-play-overlay .auto-play-overlay {
    visibility: visible;
    pointer-events: visible;
}

.auto-play-container {
    display: grid;
    grid-template-columns: var(--_btn-size) 1fr var(--_btn-size);
    grid-gap: var(--_grid-gap);
    position: absolute;
    inset: 50% 0px auto 0px;
    transform: translateY(-50%);
    padding: var(--_offset);
    color: var(--white);

    .next {
        grid-column: 3;
        grid-row: 1;
    }

    .prev {
        grid-column: 1;
        grid-row: 1;
    }

    .autoplay-counter-holder {
        grid-column: 2;
        font-size: var(--_count-size);
        font-weight: bold;
        text-align: center;
        position: relative;

        @media (max-width: ( $landscape - 1px )) {
            line-height: 1;
        }
        &:not(.active) {
            visibility: hidden;
        }
    }

    .autoplay-counter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    button {
        font-weight: bold;
        background-color: transparent;
        border: 2px solid currentColor;
        border-radius: 5px;
        color: inherit;
        padding: 9px 15px;
        text-align: center;
        align-self: center;
        justify-self: center;
        font-size: 1.1rem;
        cursor: pointer;
        transition: all 0.15s linear;

        * { pointer-events: none; }

        &:hover {
            border-color: var(--focus-color);
        }

        @media (max-width: ( $landscape - 1px )) {
            padding: 9px 0px;

            .label { display: none; }
            .icn { margin: 0px; }
        }
    }

    [data-autoplay] {
        --_color: inherit;
        --_check: 0;
        --_check-border: var(--white);
        --_check-bg: transparent;
        grid-column: 2;
        background-color: transparent;
        color: var(--_color);
        padding: 9px 15px;
        margin-top: 40px;

        &.active {
            --_check: 1;
            --_check-border: var(--green);
            --_check-bg: var(--green);
        }

        @media (max-width: ( $landscape - 1px )) {
            grid-column: 1 / -1;
            margin-top: 20px;
        }

        .check {
            border-radius: 3px;
            border: 2px solid var(--_check-border);
            background-color: var(--_check-bg);
            display: inline-block;
            margin-right: 10px;
            padding: 5px;
            margin-left: -5px;
            transition: all 0.15s linear;

            .icn {
                opacity: var(--_check);
            }
        }
    }

    .autoplay-spinner {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 50%;
        top: 50%;
        transform:
            translate(-50%, -50%)
            scale( var(--_spinner-transform, 1));

        span {
            position: absolute;
            border-radius: 100% 0 0 100% / 50% 0 0 50%;
            border: 10px solid #666;
            border-right: none;
            width: 50%;
            height: 100%;
            left: 0px;
            top: 0px;
            animation: autoplayRotate 5s linear infinite;
            transform-origin: right center;
            z-index: 2;
        }

        .second { animation-delay: 1s; border-color: #444; z-index: 1; }
    }
}

@keyframes autoplayRotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}
