//
//  Layout of streaming widget
//  - contains the basic layout of the live and archive stream widget
//

// Default is archive
.stream-widget {
    .spinner { display: none; }
}

.stream-widget.is-loading {

    .stream-wrapper {
        background-color: transparent;
        outline-color: transparent;
    }

    .placeholder-player, .film-fragments, .stream-side {
        opacity: 1;
        border-radius: 5px;
        background-color: $e4;
        min-height: 60px;
        border: 0px;

        & > * { opacity: 0; }
        .spinner { opacity: 1; }
    }

    .spinner {
        display: block;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        .icn {
            display: block;
            font-size: 24px;
            color: $softGrey;
            margin-bottom: 10px;
        }

        .label {
            display: block;
            color: #666;
            font-size: var(--font-size-small);
        }
    }

    .stream-holder { background-color: transparent; }
    .stream-side { margin-left: $offset; }
    .film-fragments { margin-top: $offset; }

    // Hide live-stream when not active
    // TODO: Dit is niet echt mooi en onverwacht
    &.live-stream {
        display: none;
    }

    // Hide presentation
    .presentation { display: none; }
}
