//
//  Load Streaming widgets
//  - streaming widget are used to show a Live or archive stream with info (side)
//

@use 'sass:math';

$offset: 20px; // Default space between elements


// Breakpoints for responsive live-stream. The elements change in size based on screensize.

// On Mobile portrait is info below player

// On Mobile landscape is player and info are side-by-side at 50/50 size
$first-breakpoint: $mobileLandscape;
$first-size: 50%;

// On Tablet portraitinfo has a fixed width of $second-size
$second-breakpoint: $portrait;
$second-size: 250px;

// On Landscape (and larger) the info is a third of the available space
$third-breakpoint: $landscape;
$third-size: math.div(100%, 3);

// Max width of archive streaming side-bar
$archive-max-right: 300px;


// Color of borders in several archive elements
$archive-border-color: var(--soft-grey);

// When to switch between zoomed layouts
$zoom-breakpoint: $landscape;

@import "layout";
@import "loading";

// Player elements
@import "placeholder-player";
@import "status";


// Load styling of components
@import "_base";
@import "fragments";
@import "embed";
@import "film-fragments";
@import "info";

// Presentation
@import "presentation";
@import "view-sheet";

// Auto play overlay
@import "autoplay-overlay";

// Audio only
$default-ratio: 30%;
$portrait-ratio: 40%;
$mobile-ratio: 66%;

@import "audio-overlay";
@import "safari-audio";


//
// TODO:
//  Mobiele weergeva uitwerken
//  voor nu verborgen op mobile-portrait
//
.fragments {
    @media (max-width: ( $mobileLandscape - 1px )) {
        display: none;
    }
}
