//
//  Alternative streams
//  - widget showing alternative streams
//  - used on home when there are multiple streams
//  - user on meetingpage when there are multiple streams on this meeting
//

.alternative-streams {
    clear: both;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    display: none;

    &.active {
        display: block;
    }

    .streams-holder {
        position: relative;
        box-sizing: border-box;

        @media ( min-width: $mobileLandscape ){
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }

    .alt-stream {
        padding: 20px;
        position: relative;
        height: auto;
        overflow: hidden;
        border-right: 1px dotted #ccc;
        background: $f5;
        box-sizing: border-box;
        text-decoration: none;
        color: inherit;
        transition: all 0.15s linear;

        @media ( min-width: $mobileLandscape ) {
            flex: 0 0 50%;
        }

        @media ( min-width: $landscape ){
            flex: 0 0 30%;
        }

        &:hover {
            background-color: $white;
            box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );

            h2, h3, h4 {
                color: $hover-blue;
            }
        }
    }

    @media print {
        display: none;
    }

    h2, h3 {
        margin-bottom: 10px;
    }

    h4 {
        margin-bottom: 5px;
    }

    ul {
        list-style: none;
        padding-left: 0px;
    }

    li {
        font-size: var(--font-size);
        margin-right: 10px;

        span { font-weight: bold; display: inline-block; width: 60px; }
    }

    .button { float: right; }

    a, a:link { display: block; clear: both; }

    .live_film_container h4:before,
    .live_audio_container h4:before {
        content: "\f130";
        font-family: $font-awesome;
        font-weight: bold;
        display: inline-block;
        color: getVar( --primary, $primary );
        margin-right: 10px;
    }

    .live_film_container h4:before {
        content: "\f03d";
    }

    // Extra margin on meeting page
    .meeting-page & {
        margin: 30px 0px 50px 0px;
    }
}

