//
//  Audio overlay
//  - styling for audio-only streaming element
//  - shows some stream-data/info under the audio-player. To make up for the lack of video
//

// Audio overlay with some info, to fill the lack of video
.audio-overlay {
    opacity: 0;
    transition: all 0.15s linear;

    &, .holder {
        position: absolute;
        left: 0px;
        top: 0px;
        pointer-events: none;
        right: 0px;
        color: $white;
    }

    &:before {
        content: " ";
        display: block;

        padding-top: $mobile-ratio;

        @media ( min-width: $mobileLandscape ) {
            padding-top: $default-ratio
        }

        @media ( min-width: $portrait ) {
            padding-top: $portrait-ratio
        }

        @media ( min-width: $landscape ) {
            padding-top: $default-ratio
        }
    }

    .holder {
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        top: 40%;
        transform: translateY( -50% );

        @media ( min-width: $mobileLandscape ) {
            flex-direction: row;
            text-align: left;
        }
    }

    .icn-holder {
        @include equalAll(70px);

        flex: 0 0 auto;
        background-color: $primary;
        border: 4px solid $white;
        border-radius: 100%;
        text-align: center;
        margin: 20px;
        transition: all 0.15s linear;

        @media ( min-width: $mobileLandscape ) {
            margin-left: 30px;
        }

        .icn {
            font-size: 32px;
            display: block;
            line-height: 60px;
            position: relative;
            left: -1px;
        }
    }

    @media ( min-width: $mobileLandscape ) {
        .current-group { flex: 1 1 auto; }
    }

    .group-small, .group-name {
        display: block;
        line-height: 1.2;
        text-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.15 );
        font-weight: bold;
    }

    .group-name { font-size: 2rem; }
}

// Syling based on status of media
.is-ready ~ .audio-overlay {
    opacity: 0.6;
}

.is-playing ~ .audio-overlay {
    opacity: 1;

    .icn-holder { background-color: $green; }
}
