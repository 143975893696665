//
//  Embed
//  - embed function allows user to select a fragment and create an embed code
//

.embed-fragment {
    position: absolute;
    top: calc( 100% - 39px );
    left: 0px;
    width: 100%;
    overflow: hidden;
    max-height: 40px;
    background-color: $f5;
    height: 100%;
    transition: all 0.15s linear;

    form {
        padding: 10px 20px 20px 20px;
        position: relative;
        box-sizing: border-box;
        transition: all 0.15s linear;
    }

    textarea {
        height: 60px;
        font-size: 7pt;
        resize: none;
        word-break: break-all;

        @media ( min-width: $desktop ) {
            height: 120px;
            font-size: 9pt;
        }
    }

    label {
        width: 100%;
        flex: 1 1 auto;
        font-size: var(--font-size-small);
        font-weight: bold;
    }

    @media ( max-width: ($landscape - 1px )) {
        .hide-mobile { display: none; }
    }

    .block-header {
        cursor: pointer;
        transition: all 0.15s linear;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &:hover { background-color: $f5; }

        * { pointer-events: none; }
        h6 { flex: 1 1 auto; }

        .pointer {
            align-self: center;
            flex-basis: 24px;

            .icn { transform: rotate(0deg); }
        }
    }

    .label-holder, .start-end-holder {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .label-holder button { flex: 0 0 auto; }

    .start-end-holder {
        .field-group { flex: 0 0 49%; }

        input, .input {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }

        .icn-only {
            position: absolute;
            right: 2px;
            top: 1px;
            line-height: 17px;
        }
    }

    .embed-preselect {
        position: relative;
        min-height: auto;

        .embed-label {
            position: absolute;
            top: 100%;
            right: 0px;
            background-color: $darkGrey;
            color: $white;
            font-size: var(--font-size-small);
            padding: 5px;
            border-radius: 3px;
            opacity: 0;
            margin-top: -5px;
            transition: all 0.15s linear;
            z-index: 1;
            pointer-events: none;

            &:before {
                background-color: inherit;
                width: 12px;
                height: 12px;
                content: " ";
                position: absolute;
                top: -10px;
                right: 3px;
                transform-origin: center center;
                transform: rotate(-45deg) translateX(-50%);
            }
        }

        &:hover, &:focus {
            .embed-label {
                margin-top: 5px;
                opacity: 1;
            }
        }
    }

    .embed-code {
        margin-top: 15px;
        border-top: 1px solid $e4;
        padding-top: 15px;

        &:not(.show-embed) { display: none; }
    }

    #embedTimeFormat {
        display: block;
        font-size: var(--font-size-small);
        text-align: right;
        margin-top: -10px;
    }
}

.embed-message {
    display: block;
    position: absolute;
    pointer-events: none;
    left: 0px;
    right: 0px;
    background-color: rgba( #888, 0.9 );
    top: 0px;
    bottom: 0px;
    transform-origin: center;
    transform: scale( 0.8 );
    opacity: 0;
    transition: 0.15s linear all;

    .icn-only {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .msg-txt {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        font-size: var(--font-size-larger);
        line-height: 1.4;
        color: $white;
        font-weight: bold;
        text-align: center;
        left: 15px;
        right: 15px;

        .icn {
            display: block;
            font-size: 40pt;
            margin-bottom: 10px;
        }
    }

    &.active {
        transform: scale(1);
        opacity: 1;
        pointer-events: visible;
    }
}

// Active style, when activated or when there are no fragments
.no-fragments .embed-fragment,
.embed-fragment.active {
    top: -1px;
    max-height: 800px;

    .pointer .icn { transform: rotate(180deg); }
}

// Hide elements when no-fragmets

.no-fragments {

    // Hide select current fragment button when there are no fragments
    .embed-preselect { display: none; }

    // Hide pointer and disable click
    .stream-side .block-header {
        pointer-events: none;

        .pointer { display: none; }
    }
}

