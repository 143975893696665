//
//  Styling of presentation element(s)
//  - layout is defined in archive-stream/presentation-layout.scss
//

$pres-padding: 5px;
$pres-color: $hover-blue;
$pres-transition: all 0.15s linear;

// small, button view
.presentation {
    display: none;
    overflow: hidden;
    width: 100px;
    height: 100px;
    padding: $pres-padding;
    background-color: $white;
    box-sizing: border-box;
    z-index: 2;
    box-shadow: 0px 0px 8px rgba(0,0,0,.8);
    margin: 15px;

    @media ( min-width: $landscape ) {
        width: 120px;
        height: 120px;
    }

    .sheet {
        width: 100%;
        height: auto;
        display: block;
    }

    a, .button {
        * { pointer-events: none; }
    }

    &.has-sheet {
        display: block;

        .no-content { display: none; }
    }

    .no-content {
        text-align: center;
        font-size: var(--font-size-small);
        min-height: 50vh;
        max-height: 80vh;
        padding: 50px 0px;

        .icn {
            display: block;
            color: $softGrey;
            font-size: 24px;
            margin-bottom: 30px;
        }

        .content-box {
            position: absolute;
            left: 0px;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    // Open / Zoom trigger
    .open-sheet {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        top: 0px;
        z-index: 1;
        border: $pres-padding solid $white;
        transition: $pres-transition;

        .trigger {
            position: absolute;
            right: 0px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-bottom-left-radius: 3px;
            background-color: $white;
            color: $pres-color;
            white-space: nowrap;
            text-align: left;
            padding-left: 5px;
            font-size: var(--font-size-small);
            transition: $pres-transition;

            .icn {
                display: inline-block;
                width: 16px;
                margin-right: 5px;
            }
        }

        &:hover {
            border-color: $pres-color;

            .trigger {
                color: $white;
                background-color: $pres-color;
                width: 85px;
            }
        }
    }

    .close-sheet {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
        text-align: center;
        display: none;

        @media ( min-width: $zoom-breakpoint ) {
            right: 15px;
            top: 15px;
            left: auto;
            bottom: auto;

            .icn { margin: 0px; }
            .label { display: none }
        }
    }
}
