//
//  Layout of streaming widget
//  - contains the basic layout of the live and archive stream widget
//

// Default is archive
.stream-widget {
    position: relative;

    .stream-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
    }

    @media ( min-width: $first-breakpoint ) {
        .stream-wrapper {
            flex-direction: row;
        }
    }

    .stream-main { flex: 1 1 auto; }

    .stream-side {
        flex: 0 0 auto;
        margin-top: $offset;
        position: relative;

        @media (min-width: $first-breakpoint) {
            margin-top: 0px;
            flex-basis: $first-size;
        }

        @media (min-width: $second-breakpoint) {
            flex-basis: $second-size;
        }

        @media (min-width: $third-breakpoint) {
            flex-basis: $third-size;
        }
    }

    // Position of presentation
    .presentation {
        position: absolute;
        right: 0px;
        top: 0px;

        @media (min-width: $first-breakpoint) {
            right: $first-size;
        }

        @media (min-width: $second-breakpoint) {
            right: $second-size;
        }

        @media (min-width: $third-breakpoint) {
            right: $third-size;
        }
    }
}

.currentFragmentsText {
    text-align: center;
}

//
// Live stream specific layout
//
.live-stream {
    @media ( min-width: $mobileLandscape ){
        .stream-side {
            margin-left: 0px;
        }
    }
}

//
// Archive stream specific layout
//
.archive-stream {
    // Limit size of speakers fragments
    @media ( min-width: $mobileLandscape ){
        .stream-side {
            max-width: $archive-max-right;
            margin-left: 0px;
            min-height: 330px;
            overflow: hidden;
        }

        .presentation {
            right: $archive-max-right;
        }
    }
}
