//
//  Stream info
//

.stream-info {
    padding: 20px;
    height: 100%;
    position: relative;

    @media ( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: column nowrap;
    }

    .group-info {
        position: relative;
        flex: 1 1 auto;

        .type {
            position: absolute;
            top: 0px;
            right: 0px;
            color: $white;
            text-align: center;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 100%;
            font-size: var(--font-size-small);
        }

        .group {
            font-weight: bold;
            font-size: var(--font-size-large);
            line-height: 1.2;
            margin-bottom: 5px;

            dd {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
            }
        }

        .group, .location { padding-right: 40px; }
        .location, .description { font-size: var(--font-size-small); }

        .location * { display: inline-block; }

        .description {
            margin: 10px 0px;
            line-height: 1.6;
            position: relative;
            max-height: 41px;
            overflow: hidden;

            p { margin: 0px; }

            &.active {
                max-height: auto;
            }
        }

        @media ( min-width: $landscape ) {
            .type {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: var(--font-size);
            }

            .group, .location { padding-right: 50px; }
            .location, .description { font-size: var(--font-size); }
        }
    }

    .toolbar {
        flex: 0 0 auto;
    }

    .agenda-button {
        display: block;
        margin-top: auto;
        width: 100%;

        @media ( max-width: ( $portrait - 1px ) ) {
            display: none;
        }
    }

    .current-agenda {
        box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
        background-color: $white;
        padding: 12px;
        color: inherit;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        transition: all 0.15s linear;
        text-decoration: none;
        margin: 10px 0px;

        .agenda-item { flex: 1 1 auto; }

        .button {
            flex: 0 0 auto;

            @media ( min-width: $portrait ) {
                display: none;
            }
        }

        h3 {
            font-size: var(--font-size-small);
            margin: 0px;
            line-height: 1;
            font-weight: normal;
        }

        .nr, .name { font-weight: bold; }
        .nr { color: getVar(--primary, $primary); }
    }
}
