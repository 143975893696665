//
//  Presentation layout
//  - defines basic layout of the presentation and switch between normal and zoomed view
//  - Presentation zoomed view has two different layouts dependent on screen size
//      - on smaller screens: player in top-right corner, current sheet is full screen and metadata is hidden
//      - on larger screens: player and metadata in left-column, current sheet in right column
//

.view-sheet {

    // Normal position of presentation is defined in streaming/layout.scss

    // All views
    // Reset presentation element
    .presentation {
        position: relative;
        width: 100%;
        right: 0px;
        height: auto;
        margin: 0px;
        box-shadow: none;
    }

    .open-sheet { opacity: 0; }
    .close-sheet { display: block; }

    .film-fragments { display: none; }

    // Smaller screens
    @media ( max-width: ( $zoom-breakpoint - 1px )){
        .stream-side { display: none; }

        .stream-holder {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 30%;
            z-index: 3;
        }

        .placeholder-player {
            .play-icn {
                font-size: var(--font-size);
                @include equalAll(30px);
            }
        }
    }

    // Larger screens
    @media ( min-width: $zoom-breakpoint ) {

        .stream-wrapper {
            display: grid;
            grid-template-columns: [start] 300px  [presentation] 1fr;
            grid-template-rows: [start] auto [outflow] 1fr;
        }

        .stream-main {
            grid-column: start;
        }

        .stream-side {
            grid-column: start;
        }

        .presentation {
            grid-column: presentation;
            grid-row: 1 / 3;
        }
    }

    // Tweak styling of fragments
    .stream-side {
        border-left: 0px;
        border-right: 1px solid $archive-border-color;
    }

    // For when presentation sheet is deselected while view is zoomed
    .presentation:not(.has-sheet) {
        &, .no-content { display: block; }
        .sheet { display: none; }
    }
}
